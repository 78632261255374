<template>
    <st-modal
        id="real-estate-modal"
        hide-header-delimiter
        hide-footer-delimiter
        hide-footer
        hideable
        :noCloseOnBackdrop="true"
        ref="modal"
        customClass="form-modal md-size"
    >
        <template #header>
            <p class="font-size-h5 font-weight-bold mb-0 pb-0">
                {{ $t("REAL_ESTATE.MODAL.SUMMARY_HEADER") }}
            </p>
        </template>
        <template #body>
            <ul v-for="item in items" :key="item.cadastral_number" class="real-estate-list">
                <li>{{ `${item.cadastral_number}, ${displayAddress(item)}` }}</li>
            </ul>

            <st-button class="mt-6" variant="primary" :callback="cancel">Inapoi</st-button>
        </template>
    </st-modal>
</template>

<script>
export default {
    name: "RealEstateSummaryModal",
    props: {
        items: {
            type: Array,
            required: false,
        },
    },
    methods: {
        hide() {
            this.$refs["modal"].hide();
        },
        show() {
            this.$refs["modal"].show();
        },
        cancel() {
            this.hide();
        },
        displayAddress(item) {
            return `${item.county}, ${item.territorial_administrative_unit}`;
        }
    },
};
</script>
